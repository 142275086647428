<template>
    <u-dialog v-model="shows" v-bind="{ title }" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <div class="mt-n16px">
            <product-item hideDivider isLink v-bind="{ product }" />
        </div>

        <v-divider class="mt-md-8px" />
        <!-- 색상선택 / 옵션선택 / 추가상품 -->
        <product-form-options v-model="carts" v-bind="{ product }" />

        <!-- 배송옵션 -->
        <product-form-shipping v-model="shippingOption" v-bind="{ product }" />

        <!-- 선택한 옵션 -->
        <product-form-carts v-model="carts" />

        <template #actions>
            <div class="w-100">
                <div>
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto"> 상품금액 </v-col>
                        <v-col cols="auto">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20 font-size-lg-24 line-height-1 txt--dark"> {{ productPrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">할인금액</v-col>
                        <v-col cols="auto">-{{ discountPrice?.format?.() }} 원</v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">등급할인</v-col>
                        <v-col cols="auto">-{{ levelDiscountPrice?.format?.() }} 원</v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">배송비</v-col>
                        <v-col cols="auto">{{ deliveryPrice?.format?.() }} 원</v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">총 금액</v-col>
                        <v-col cols="auto">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20 font-size-lg-24 line-height-1 primary--text"> {{ totalPrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </div>

                <div class="btn-wrap">
                    <v-row class="row--sm">
                        <v-col>
                            <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-large" @click="shows = false">취소</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn v-bind="{ ...btn_primary, ...$attrs }" block class="v-size--xx-large" @click="save">{{ saveText }}</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { initProduct, initShopShippingOption, PRODUCT_TYPES } from "@/assets/variables";
import { btn_primary, btn_tertiary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ProductItem from "../product/product-item.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import ProductFormCarts from "@/components/client/shop/products/form/product-form-carts.vue";
import ProductFormOptions from "@/components/client/shop/products/form/product-form-options.vue";
import ProductFormShipping from "../../shop/products/form/product-form-shipping.vue";

export default {
    props: {
        value: { type: Array, default: () => [] }, // carts
        _product: { type: String },
        title: { type: String, default: "주문수정" },
        saveText: { type: String, default: "수정" },
        isNew: { type: Boolean, default: false },
    },
    components: {
        PageSection,
        UDialog,
        ProductItem,
        TitWrapXSmall,
        VerticalTable,
        ProductFormCarts,
        ProductFormOptions,
        ProductFormShipping,
    },
    data: () => ({
        btn_primary,
        btn_tertiary,

        carts: [],
        shippingOption: initShopShippingOption(),

        product: initProduct(),

        shows: false,
    }),
    computed: {
        ...mapGetters(["userLevel"]),
        productPrice() {
            return this.$getProductPrice(this.carts);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts);
        },
        discountPrice() {
            return this.$getDiscountPrice(this.carts);
        },
        levelDiscountPrice() {
            return this.$getLevelDiscountPrice(this.carts, this.userLevel);
        },
        totalPrice() {
            return this.productPrice + this.deliveryPrice - this.discountPrice - this.levelDiscountPrice;
        },
    },
    mounted() {
        this.sync();
        this.init();
        // this.init().then(this.sync);
    },
    watch: {
        value() {
            if (!this.isNew) this.sync();
        },
        shows() {
            if (!this.isNew) this.sync();
        },
        _product() {
            this.init();
        },
        shippingOption() {
            this.carts = this.carts.map((item) => ({
                ...item,
                _shipping: this.shippingOption?._shipping,
                _shippingOption: this.shippingOption?._id,
                shippingOption: initShopShippingOption(this.shippingOption),
                shippingCode: this.shippingOption?.code,
                shippingBundleType: this.shippingOption?.bundle?.type,
            }));
        },
    },
    methods: {
        sync() {
            this.carts = JSON.parse(JSON.stringify(this.value));
            this.shippingOption = this.carts?.find?.(({ shippingOption }) => shippingOption)?.shippingOption;
        },
        async init() {
            let { product } = await api.v1.shop.products.get({ _id: this._product });
            this.product = product;

            if (!this.isNew) return;

            const isVarietyBundle = product?.type == PRODUCT_TYPES.VARIETY_BUNDLE.value;
            const isProductBundle = product?.type == PRODUCT_TYPES.PRODUCT_BUNDLE.value;
            const isOptionEnabled = product?.optionsConf?.enabled;
            const isChoiceEnabled = product?.choiceesConf?.enabled;

            if (!isVarietyBundle) {
                if (!isOptionEnabled && !isChoiceEnabled) {
                    if (isProductBundle) {
                        this.$set(this.carts, 0, {
                            _product: product._id,
                            product,
                            name: "본품",
                            price: product.price,
                            salePrice: product.salePrice,
                            discountPrice: product.discountPrice,
                            stock: product.stock__bundleds,
                            amount: 1,
                        });
                    } else {
                        this.$set(this.carts, 0, {
                            _product: product._id,
                            product,
                            name: "본품",
                            price: product.price,
                            salePrice: product.salePrice,
                            discountPrice: product.discountPrice,
                            stock: product.stock,
                            amount: 1,
                        });
                    }
                }
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const _carts__new = this.carts.map(({ _id }) => _id);
                const _carts__old = this.value.map(({ _id }) => _id);

                const { shippingOption } = this;
                const added = this.carts.filter(({ _id }) => !_carts__old.includes(_id));
                if (added.length) await api.v1.me.carts.post({ carts: added, shippingOption });

                const deleted = this.value.filter(({ _id }) => !_carts__new.includes(_id));
                await Promise.all(deleted.map(async (cart) => await api.v1.me.carts.delete(cart)));

                const modified = this.carts.filter(({ _id }) => _carts__old.includes(_id));
                await Promise.all(modified.map(async (cart) => await api.v1.me.carts.put(cart)));

                alert(`${this.saveText}되었습니다`);
                this.shows = false;
                this.$emit("search");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
